﻿var id;
var direction;
var template;
var activeContent;
var messages;
var filteredMessage;
var loggedInUserDetails;
var isFromLogin;
var modalPopup;
var config;
var index = 0;
var isFirstTimeLogin = false;
var defaultLanguageCulture = 'en-US';
var serviceDataSourceUId = '00100000-0020-0000-0000-000000000000';
var correlationUId = 'CorrelationUId';
var correlationUIdValue = '00000000-0000-0000-0000-000000000000';
var modalPopupTemplateUId = '00200000-0040-0000-0000-000000000000';
var disclaimerMessageName = 'ClientDataDisclaimer01';
var PLATFORM_COOKIE = 'platform';
var APPLICATION_COOKIE = 'application';
var USER_COOKIE = 'user';
var filteredLoginMessages = [];
var userCookieName = "LoggedInUserId";

function setModalFrequency(message) {
	if (message && message.ShowAfterInterval > 0 && message.Name === disclaimerMessageName) {
		id = setInterval(function () {
			showUserNotificationContent(message);
		}, message.ShowAfterInterval * 1000);
	}
}

function getConfig() {
	var headerData = setHeaderConfiguration();
	getDetails(headerData, config.ServiceUrl).done(function (data) {
		if (data) {
			messageObject = data;
			config.Templates = messageObject.Templates;
			if (config && config.DataSourceUId === serviceDataSourceUId) {
				if (data && data.DataSources && data.DataSources.length > 0) {
					var dataSource = data.DataSources.filter(x => x.DataSourceUId === serviceDataSourceUId)[0];
					if (dataSource) {
						getUserNotificationMessages(dataSource);
					}
				}
			} else {
				config.Messages = messageObject.Messages;
				setActiveLanguage(config.ActiveLanguage);
				getShowHideNotificationDetails();
			}
		}
	}).fail(function (code, status) {
		printLog('Error' + code + status);
	});
}

function getUserNotificationMessages(dataSource) {
	headerData = setHeaderConfiguration();
	var endPointUrl = config.BaseUrl + dataSource.EndPointUrl;
	getDetails(headerData, endPointUrl).done(function (data) {
		if (data) {
			config.Messages = data;
			setActiveLanguage(config.ActiveLanguage);
			getShowHideNotificationDetails();
		}
	}).fail(function (code, status) {
		printLog('Error' + code + status);
	});

}

function getShowHideNotificationDetails() {
	if (messages && messages.length > 0) {
		isFromLogin = true;
		if (config && config.Messages && config.Messages.length > 0) {
			filteredLoginMessages = config.Messages.filter((x => x.ShowOnEveryLogin || x.ShowOnEveryLogin === undefined || x.ShowOnEveryLogin === null));
			filteredLoginMessages = filteredLoginMessages.filter((x => x.ShowOnFirstLogin !== true));
		}
		getUserNotificationMessageDetails(messages[index]);
	}
}

function getUserNotificationMessageDetails(filteredMessage) {
	isFirstTimeLogin = false;
	if (filteredMessage && (filteredMessage.IsEnabled || filteredMessage.IsEnabled === undefined || filteredMessage.IsEnabled === null)) {
		if (filteredMessage && filteredMessage.ShowOnFirstLogin) {
			if (filteredMessage.UseService) {
				getAccountNotificationDetails(filteredMessage);
			} else if (filteredMessage.UseCallback) {
				isFirstTimeLogin = true;
				createCallBackEvent('GetUserNotificationCallBack', 'MessageUId', filteredMessage.MessageUId, filteredMessage);
			} else {
				showUserNotificationContent(filteredMessage);
			}
		} else {
			if (filteredMessage && (filteredMessage.ShowOnEveryLogin || filteredMessage.ShowOnEveryLogin === undefined || filteredMessage.ShowOnEveryLogin === null)) {
				//if (!(filteredLoginMessages && filteredLoginMessages.length > 1)) {
				// Start - Cookie
				if (!checkCookie(filteredMessage)) {
					showUserNotificationContent(filteredMessage);
				} else {
					if (filteredMessage.ShowAfterInterval > 0) {
						setModalFrequency(filteredMessage);
					}
					showNextMessage(true);
				}
				//} else {
				//	showUserNotificationContent(filteredMessage);
				//}
				// End - Cookie
			} else {
				showNextMessage(true);
			}
		}
	} else {
		showNextMessage(true);
	}
}

function setHeaderConfiguration() {
	var headerData = {
		'Access-Control-Allow-Origin': '*',
		'withCredentials': true,
		'content-type': 'application/json'
	}
	if (config) {
		if (config.TokenKey) {
			headerData['Authorization'] = `Bearer ${sessionStorage.getItem(config.TokenKey)}`;
		} else if (config.Token) {
			headerData['Authorization'] = `Bearer ${config.Token}`;
		}
	}

	return headerData;
}

function getDetails(headers, url) {
	return $.ajax({
		url: url,
		type: 'GET',
		headers: headers,
		async: true
	});
}

function getAccountNotificationDetails(message) {
	if (message && message.AccountServices && message.AccountServices.length > 0) {
		const accountService = message.AccountServices.filter(x => x.EndPointType === 'GET')[0];
		headerData = setHeaderConfiguration();
		headerData['MessageUId'] = message.MessageUId;
		if (accountService) {
			const accountUniqueKeyIdColumn = accountService.AccountUniqueKeyIdColumn;
			var endPointUrl = config.BaseUrl + accountService.EndPointUrl;
			if (accountUniqueKeyIdColumn) {
				endPointUrl = endPointUrl ? endPointUrl.replace('{' + accountUniqueKeyIdColumn + '}', config.LoggedInUserId) : endPointUrl;
			}
			var notificationKeyColumn = accountService.NotificationColumn;
			getDetails(headerData, endPointUrl).done(function (data) {
				if (data) {
					loggedInUserDetails = data;
					var userData;
					if (Array.isArray(data)) {
						userData = data[0];
					} else {
						userData = data;
					}
					if (userData && !userData[notificationKeyColumn]) {
						isFirstTimeLogin = true;
						showUserNotificationContent(message);
					} else {
						if (message && message.ShowAfterInterval > 0) {
							setModalFrequency(message);
						}
						showNextMessage(true);
					}
				}
			}).fail(function (code, status) {
				printLog('Error' + code + status);
			});
		} else {
			printLog('UserNotification: Account Services configuration is missing');
			showUserNotificationContent(message);
		}
	} else {
		printLog('UserNotification: Account Services configuration is missing');
		showUserNotificationContent(message);
	}
}

function saveUserResponse(message) {
	if (message && message.AccountServices && message.AccountServices.length > 0) {
		const accountService = message.AccountServices.filter(x => x.EndPointType === 'POST')[0];
		headerData = setHeaderConfiguration();
		if (accountService && loggedInUserDetails) {
			const accountUniqueKeyIdColumn = accountService.AccountUniqueKeyIdColumn;
			var endPointUrl = config.BaseUrl + accountService.EndPointUrl;
			if (accountUniqueKeyIdColumn) {
				endPointUrl = endPointUrl ? endPointUrl.replace('{' + accountUniqueKeyIdColumn + '}', config.LoggedInUserId) : endPointUrl;
			}
			var notificationKeyColumn = accountService.NotificationColumn;
			headerData[correlationUId] = correlationUIdValue;
			var itemStateKey = accountService.ItemStateKey;
			if (Array.isArray(loggedInUserDetails) && loggedInUserDetails.length > 0) {
				if (itemStateKey) {
					loggedInUserDetails[0][itemStateKey] = 1;
				}
				loggedInUserDetails[0][notificationKeyColumn] = 1;
			} else {
				if (itemStateKey) {
					loggedInUserDetails[itemStateKey] = 1;
				}
				loggedInUserDetails[notificationKeyColumn] = 1;
			}
			const request = JSON.stringify(loggedInUserDetails);
			$.ajax({
				url: endPointUrl,
				type: 'POST',
				headers: headerData,
				async: true,
				data: request,
				success: function (data) {
					printLog(data);
				},
				error: function (errorMessage) {
					printLog('Error' + errorMessage);
				}
			});
		}
	} else {
		printLog('UserNotification: Account Services configuration is missing');
	}
}

function setToken(token) {
	if (config) {
		config.Token = token;
	}
}

function openUserNotificationModalPopup(messageName) {
	disclaimerMessageName = "ClientDataDisclaimer01";
	if (messageName && config && config.Messages && config.Messages.length > 0) {
		var message = config.Messages.filter(x => x.Name === messageName)[0];
		if (message) {
			disclaimerMessageName = messageName;
			showUserNotificationContent(message, messageName);
		} else {
			showUserNotificationContent(messages[index]);
		}
	} else {
		if (messages && messages.length > 0) {
			showUserNotificationContent(messages[index]);
		}
	}
}

function showUserNotificationContent(message, messageName) {
	index = index + 1;
	if (message && message.Contents && message.Contents.length > 0 && (isFromLogin || message.Name === disclaimerMessageName)) {
		template = config.Templates.filter(m => m.TemplateUId == message.TemplateUId)[0];
		if (template) {
			var content = message.Contents.filter(m => m.LanguageCulture == config.ActiveLanguage)[0];
			if (!content) {
				content = message.Contents.filter(m => m.LanguageCulture == defaultLanguageCulture)[0];
			}
			direction = config.ActiveLanguage === "ar-SA" || config.ActiveLanguage === "he" ? 'rtl' : 'ltr';
			activeContent = content;
			if (message.Name === disclaimerMessageName) {
				destroyTimer();
			}
			if (activeContent && activeContent.Message && (message && (message.IsEnabled || message.IsEnabled === undefined || message.IsEnabled === null))) {
				if (!document.querySelector(".user-notification-modal")) {
					var html = template.Content;
					var div = '';
					if (activeContent && activeContent.Actions) {
						activeContent.Actions.forEach(item => {
							item.Title = item.Title ? item.Title : "I Understand";
							div = div +
								'<button  id="' + item.ControlId + '" class="btn  ' + (item.ActionType ? 'btn-' + item.ActionType : 'btn-primary') + ' notification-button-close"  title="' + activeContent.HelpUrl + '"  type="button" >'
								+ item.Title +
								'</button>';
						});
					}
					div = div;
					html = html.replace('{Content}', activeContent.Message);
					html = html.replace('{Title}', activeContent.Title);
					html = html.replace('{Buttons}', div);
					html = html.replace('{Direction}', direction);
					html = html.replace('{LanguageCulture}', activeContent.LanguageCulture);
					var modal = document.createElement('div');
					if (activeContent && activeContent.Macros && activeContent.Macros.length > 0) {
						activeContent.Macros.forEach(item => {
							html = html.replaceAll('{' + item.Key + '}', item.Value ? item.Value : '');
						});
					}
					html = html.replace('("")', '');
					modal.innerHTML = html;
					document.body.appendChild(modal);
					var browserCompatabiltyCheckboxChecked = false;
					var checkBox = document.querySelector("#dontshow");
					if (checkBox) {
						checkBox.addEventListener('change', function (event) {
							if (event && event.target && event.target.checked) {
								browserCompatabiltyCheckboxChecked = true;
							}
						});
					}
					var buttons = document.querySelectorAll(".notification-button-close");
					if (buttons && buttons.length > 0) {
						buttons.forEach((btn) => {
							btn.addEventListener('click', function () {
								modal.remove();
								const action = activeContent.Actions.filter(x => x.ControlId === btn.id)[0];
								if (action && action.ActionMethod) {
									createCallBackEvent('ButtonCallBackMethod', 'MethodName', action.ActionMethod, message);
								} else {
									if (action) {
										if (action.ControlId === 'AcceptAction') {
											saveUserNotificationResponse(message);
										}
									}
									if (message.Name === disclaimerMessageName) {
										if (activeContent && message.ShowAfterInterval > 0) {
											setModalFrequency(message);
										}
										saveUserNotificationResponse(message);
									} else if (browserCompatabiltyCheckboxChecked) {
										saveUserNotificationResponse(message);
									}
								}
								if (isFromLogin) {									
									setCookie(message);
								}
								showNextMessage(null, messageName);
							});
						});
					}
				}
			} else {
				showNextMessage(null, messageName);
				userNotification.destroy();
			}
		}
	}
	else {
		showNextMessage(null, messageName);
	}
}

function saveUserNotificationResponse(message) {
	if (isFirstTimeLogin) {
		isFirstTimeLogin = false;
		if (message.UseService) {
			saveUserResponse(message);
		} else if (message.UseCallback) {
			isFirstTimeLogin = false;
			createCallBackEvent('SaveUserNotificationCallBack', 'MessageUId', message.MessageUId, message);
		}
	}
}

function createCallBackEvent(callbackEvent, key, value, message) {
	printLog('UserNotification: ' + callbackEvent);
	var arguments = {};
	arguments[key] = value;
	if (!arguments['MessageUId']) {
		arguments['MessageUId'] = message.MessageUId;
	}
	const event = new CustomEvent(callbackEvent, {
		'detail': arguments
	});
	window.dispatchEvent(event);
}

function showNextMessage(fromApplication, messageName) {
	if (!messageName) {
		if (fromApplication !== undefined && fromApplication !== null) {
			index = index + 1;
		}
		if (isFromLogin) {
			if (index < messages.length) {
				getUserNotificationMessageDetails(messages[index]);
			} else {
				isFromLogin = false;
				index = 0;
			}
		}
		else {
			if (index < messages.length) {
				showUserNotificationContent(messages[index]);
			} else {
				sendToParent();
				index = 0;
			}
		}
	} else {
		sendToParent();
		index = 0;
	}
}

function sendToParent() {
	printLog('UserNotification: ModalClosed');
	const event = new Event('ModalClosed');
	window.dispatchEvent(event);
}

function setActiveLanguage(culture) {
	if (config) {
		config.ActiveLanguage = culture;
		messages = config.Messages;
	}
}

function destroyTimer() {
	if (id) {
		clearInterval(id);
	}
}

function printLog(logMessage) {
	if (config && config.IsDebugMode) {
		console.log(logMessage);
	}
}

function openModalPopup(contentType, fileLocation) {
	headerData = setHeaderConfiguration();
	var endPointUrl = fileLocation;
	getDetails(headerData, endPointUrl).done(function (data) {
		if (data) {
			template = config.Templates.filter(m => m.TemplateUId == modalPopupTemplateUId)[0];
			var html = template.Content;
			html = html.replace('{ContentType}', contentType);
			html = html.replace('{Content}', data);
			modalPopup = document.createElement('div');
			modalPopup.innerHTML = html;
			document.body.appendChild(modalPopup);
		}
	}).fail(function (code, status) {
		printLog('Error' + code + status);
	})
}

function closeModalPopup() {
	modalPopup.remove();
}

function setCookie(message,) {
	let cookieObj, msgUId, multipleMsgFlag;	
	if (message && message.CookiePolicy) {
		message.CookiePolicy = message.CookiePolicy.trim();
		if (message.CookiePolicy.toLowerCase() === PLATFORM_COOKIE) {
			cookieObj = messageObject.PlatformCookie;
		} else if (message.CookiePolicy.toLowerCase() === APPLICATION_COOKIE) {
			cookieObj = messageObject.ApplicationCookie;
		} else {
			cookieObj = createUserCookieObj();
		}
	} else {
		cookieObj = createUserCookieObj();
	}
	multipleMsgFlag = filteredLoginMessages && filteredLoginMessages.length > 1 ? true : false;
	msgUId = multipleMsgFlag ? message.MessageUId : '';
	if (cookieObj && cookieObj.Name && cookieObj.Key) {
		let cookieName = cookieObj ? cookieObj.Name : '';
		let expires;
		if (cookieObj.ExpirationPeriod) {
			const d = new Date();
			d.setTime(d.getTime() + ((cookieObj.ExpirationPeriod * 60 * 60 * 1000)));
			expires = "expires=" + d.toGMTString();
		}
		const isCookieExists = getCookie(cookieName);
		const cookieValue = multipleMsgFlag ? (isCookieExists ? getNewCookieValue(msgUId,isCookieExists)  : (!checkCookie(message) ? cookieObj.Key + "_" + msgUId : "")) : cookieObj.Key;
		if (cookieValue) {			
			document.cookie = cookieObj.Name + "=" + cookieValue + ";" + ";domain=" + cookieObj.Domain + ";path=/" + (expires ? ";expires =" + expires : "") + ";secure";
		}		
	}
}

function getNewCookieValue(messageUId, cookieValue) {
	let newCookieValue = "";
	if (messageUId && cookieValue) {
		var msgInCookieArr = cookieValue.split('_');
		if (!(msgInCookieArr && msgInCookieArr.length > 0 && msgInCookieArr.indexOf(messageUId) > -1)) {
			newCookieValue = cookieValue + "_" + messageUId;
		}
	}
	return newCookieValue;
}

function getCookie(message) {
	if (message) {
		let name = message + "=";
		let decodedCookie = decodeURIComponent(document.cookie);
		let ca = decodedCookie.split(';');
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}
}

function checkCookie(message) {
	let cookieObj;
	if (message && message.CookiePolicy) {
		message.CookiePolicy = message.CookiePolicy.trim();
		if (message.CookiePolicy.toLowerCase() === PLATFORM_COOKIE) {
			cookieObj = messageObject.PlatformCookie;
		} else if (message.CookiePolicy.toLowerCase() === APPLICATION_COOKIE) {
			cookieObj = messageObject.ApplicationCookie;
		} else {
			cookieObj = createUserCookieObj();
		}
		return checkCookieValue(cookieObj, message);
	} else {
		cookieObj = createUserCookieObj();
		return checkCookieValue(cookieObj, message);
	}
	return false;
}

function checkCookieValue(cookieObj, message) {
	let cookieName = cookieObj ? cookieObj.Name : '';
	if (cookieName) {
		const isCookieExists = getCookie(cookieName);
		if (isCookieExists) {
			if ((filteredLoginMessages && filteredLoginMessages.length > 1)) {
				// multiple msg
				var msgInCookieArr = isCookieExists.split('_');
				if (msgInCookieArr && msgInCookieArr.length > 0 && msgInCookieArr.indexOf(message.MessageUId) > -1) {
					if (cookieName === userCookieName) {
						let value = config && config.LoggedInUserId ? config.LoggedInUserId : "";
						if (msgInCookieArr && msgInCookieArr[0] === value) {
							return true;
						}
					} else {
						return true;
					}
				}
				// end multiple msg
			} else {
				// single msg
				if (cookieName === userCookieName) {
					let value = config && config.LoggedInUserId ? config.LoggedInUserId : "";
					if (isCookieExists === value) {
						return true;
					}
					//else {
					//	deleteCookie();
					//}
				} else {
					return true;
				}
				// end single msg
			}
		}
	}

	return false;
}


function createUserCookieObj() {
	return {
		Name: config && config.LoggedInUserId ? userCookieName : "",
		Key: config && config.LoggedInUserId ? config.LoggedInUserId : "",
		ExpirationPeriod: ""
	};
}

function deleteCookie() {
	cookieObj = createUserCookieObj();
	let cookieName = cookieObj ? cookieObj.Name : '';
	if (cookieName) {
		const isCookieExists = getCookie(cookieName);
		if (isCookieExists) {
			document.cookie = cookieName + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
		}
	}
}

var userNotification = (function () {
	return {
		init: function (content) {
			config = content;
			getConfig();
		},
		destroy: destroyTimer,
		showUserNotificationModalPopup: function (params) {
			openUserNotificationModalPopup(params)
		},
		setActiveLanguage: setActiveLanguage,
		setToken: setToken,
		showNextMessage: showNextMessage,
		notificationLogout: deleteCookie
	};
})(userNotification || {});
